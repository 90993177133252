import React from 'react';
import { Input, FormFeedback } from 'reactstrap';
class CustomInput extends React.Component {

  render() {
    var { field, form: { touched, errors }, ...props } = this.props;
    return (
      <React.Fragment>
        <Input
          invalid={!!(touched[field.name] && errors[field.name])}
          {...field}
          {...props} />
        {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
      </React.Fragment>
    )

  }
}
export default CustomInput;