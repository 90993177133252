import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const SettingContext = createContext();

const SettingContextProvider = (props) => {
  
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const getSettings = async () => {
      try {
        let response = await axios.get(`/get-settings`);
        if (response.data.success) {
          setSettings(response.data.setting)
        }
      } catch (err) {
        console.log("Get Settings API Error: ", err)
      }
    }
    getSettings();


  }, []);
  return (
    <SettingContext.Provider value={{ settings, setSettings }}>
      {props.children}
    </SettingContext.Provider>
  );
}

export default SettingContextProvider;