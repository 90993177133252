import React, { createContext, useState } from 'react';
export const CampaignResultContext = createContext();

const CampaignResultContextProvider = (props) => {
  const [campaignResult, setCampaignResult] = useState({});
  return (
    <CampaignResultContext.Provider value={{ campaignResult, setCampaignResult }}>
      {props.children}
    </CampaignResultContext.Provider>
  );
}

export default CampaignResultContextProvider;