import React, { useState, useEffect } from 'react';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import { axiosMiddleware } from './middleware/axios';
import UserContextProvider from './contexts/UserContext'
import SelectedCampaignContextProvider from './contexts/SelectedCampaignContext'
import CampaignContextProvider from './contexts/CampaignContext'
import SettingContextProvider from './contexts/SettingContext'
import CampaignResultContextProvider from './contexts/CampaignResultContext';
import logoSrc from "./assets/img/logo.png";
import UserDetails from './views/register-user'
import Survey from './views/survey';
import QrInstruction from './views/qrInstruction';
import Home from './views/home';
import UserExist from './views/userexist';
import ThankYou from './views/thank-you';
import Error404 from './views/error-404';
import Error500 from './views/error-500';
import userExist from './views/userexist';
axiosMiddleware();

const createBrowserHistory = require("history").createBrowserHistory

export const history = createBrowserHistory()

const App = () => {
  const [position, setPosition] = useState({
    lat:0.0,
    lng:0.0
  })
  var startPos;

  useEffect(async () => {
    await console.log("hello") 
    var geoError = await function (error) {
      console.log('Error occurred. Error code: ' + error.code);
    };
    var geoSuccess =await function (position) {
      // console.log("Position====>", JSON.stringify(position))
      startPos = position;
      console.log(startPos.coords.latitude)
      console.log(startPos.coords.longitude)
      // console.log("Position====>", JSON.stringify(position))
      console.log(startPos.coords.latitude)
      console.log(startPos.coords.longitude)
      setPosition({
        lat: startPos.coords.latitude,
        lng: startPos.coords.longitude
      })
      console.log(position)
    };
    await navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  },[])
if(position){
  return (
    <SettingContextProvider>
      <CampaignResultContextProvider>
        <UserContextProvider>
          <CampaignContextProvider>
            <SelectedCampaignContextProvider>

              <main>
                <header>
                  <nav className="navbar navbar-expand-md navbar-dark ">
                    <p align="center" style={{ width: "100%", padding: "0px", margin: "0px" }}>
                      <img src={logoSrc} className="img-fluid" style={{ "height": "6vh" }} alt="Brands On Road logo"/>
                    </p>
                  </nav>
                </header>
                <Router history={history}>
                  <Switch>
                    {/* <Route exact path="/" name="User Form " render={props => <UserDetails position={position} {...props} />} />
                    <Route exact path="/survey" name="Survey Form " render={props => <Survey {...props} />} />
                    <Route exact path="/thank-you" name="ThankYou Form " render={props => <ThankYou  {...props} />} /> */}

                    <Route path="/" name="Home" component={Home} />
                    {/* <Route exact path="/survey" name="Survey Form " component={Survey} />
                    <Route exact path="/thank-you" name="ThankYou Form" component={ThankYou} />
                    <Route exact path="/qrInstruction" name="Qr Instruction" component={QrInstruction} />
                    <Route exact path="/userExist" name="user already done the survey" component={userExist} /> */}
                    <Route exact path="/error-404" name="Error 404 " component={Error404} />
                    <Route exact path="/error-500" name="Error 500 " component={Error500} />
                  </Switch>
                </Router>
              </main>
            </SelectedCampaignContextProvider>
          </CampaignContextProvider>
        </UserContextProvider>
      </CampaignResultContextProvider>
    </SettingContextProvider>

  );
}

}

export default App;