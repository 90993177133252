import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { SelectedCampaignContext } from '../contexts/SelectedCampaignContext';
import { CampaignContext } from '../contexts/CampaignContext';
import { CampaignResultContext } from '../contexts/CampaignResultContext';
import { UserContext } from '../contexts/UserContext';
import thankYouImage from "../assets/img/thank-you.png"
import axios from 'axios';
import { history } from '../App';

const ThankYou = () => {
  const { thankYouCampaign, surveyCampaign } = useContext(CampaignContext);
  const { selectedCampaigns } = useContext(SelectedCampaignContext);
  const { campaignResult } = useContext(CampaignResultContext);

  const { user } = useContext(UserContext) 
  // if (Object.entries(user).length === 0 && user.constructor === Object) {
  //   toastr.error('Please fill the user information')
  //   history.push(`/`);
  // }
  useEffect(() => {
    async function trackThankYouCampaign() {
      try {
        await axios.post(`/track-thank-you-campaign`, {
          thankYouCampaign: thankYouCampaign.map(tc => tc.id.toString()),
          id: campaignResult.id
        });

      } catch (err) {
        console.log("PopUp Campaign Track API error")
      }
    }
    if(campaignResult.id){
      trackThankYouCampaign();
    }
    
  }, [thankYouCampaign, campaignResult]);
  const populateThankYouCampaign = () => {
    return thankYouCampaign.map((tc, index) => {
      return (
        <Col md="12" key={index} style={{ textAlign: 'center' }}>
          <img src={tc.logo} className="mb-4 img-fluid" alt="Thank You Campaign"/>
          <br />
          <a href={tc.link} style={{ color: "white", cursor: "pointer" }} target="_blank" rel="noopener noreferrer">Click to win!!</a>
        </Col>
      )
    })
  }
  const populateSelectedCampaign = () => {
    return surveyCampaign.filter(sc => selectedCampaigns.includes(sc.id)).map((campaign, index) => {
      return (
        <React.Fragment key={index}>
          <Col xs="4">
            <img src={campaign.logo} alt="Thank You Campaign" className="img-fluid"/>
          </Col>
          <Col>
            <Row style={{ color: "black" }}>
              {campaign.name}
            </Row>
            <Row>
              <a href={campaign.link} target="_blank" style={{ color: "white" }} rel="noopener noreferrer">{campaign.link}</a>
            </Row>
          </Col>
        </React.Fragment>
      )
    })
  }
  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col lg="12" style={{ textAlign: 'center' }}>
          <img src={thankYouImage} />
        </Col>
        <Col lg="4" className="mt-3" style={{ textAlign: 'center' }}>
          <h3 style={{ "color": "white" }} >Thank you!! <br /> ขอขอบคุณที่เข้าร่วมกิจกรรมลุ้นรับรางวัลกับเรา</h3>
        </Col>
      </Row>
      <Row className="justify-content-center " >
        {populateThankYouCampaign()}
      </Row>
      <Row className="justify-content-center mt-4" >
        {populateSelectedCampaign()}
      </Row>
    </React.Fragment>
  )
}

export default ThankYou;