import React, { createContext, useState } from 'react';
export const SelectedCampaignContext = createContext();

const SelectedCampaignContextProvider = (props) => {
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  return (
    <SelectedCampaignContext.Provider value={{ selectedCampaigns, setSelectedCampaigns }}>
      {props.children}
    </SelectedCampaignContext.Provider>
  );
}

export default SelectedCampaignContextProvider;