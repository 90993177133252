import React, { useState, useContext } from 'react';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { Loader } from '../components/loader';
import { UserContext } from '../contexts/UserContext';
import { SelectedCampaignContext } from '../contexts/SelectedCampaignContext';
import { CampaignContext } from '../contexts/CampaignContext';
import { CampaignResultContext } from '../contexts/CampaignResultContext';
import { history } from '../App';
const Survey = () => {

  const { surveyCampaign } = useContext(CampaignContext);
  const { selectedCampaigns, setSelectedCampaigns } = useContext(SelectedCampaignContext);
  
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext)
  const { campaignResult } = useContext(CampaignResultContext)
  // if (Object.entries(user).length === 0 && user.constructor === Object) {
  //   toastr.error('Please fill the user information')
  //   history.push('/');
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedCampaigns.length) {
      try {
        setLoading(true)
        let response = await axios.post(`/campaign-details`, {
          selectedCampaigns: selectedCampaigns.map(sc=> sc.toString()),
          presentedCampaigns: surveyCampaign.map(c => c.id.toString()),
          userId: user.id,
          id: campaignResult.id
        });
        if (response.data.success) {
          toastr.success(response.data.message);
          history.push('/thank-you')
        }
        setLoading(false);

      } catch (err) {
        setLoading(false)
      }
    } else {
      toastr.error('Please check survey')
    }

  }

  const handleChange = (e) => {
    if (e.currentTarget.checked) {
      setSelectedCampaigns([...selectedCampaigns, parseInt(e.currentTarget.value)])
    } else {
      setSelectedCampaigns(selectedCampaigns.filter(x => x !== parseInt(e.currentTarget.value)))
    }
  }

  const populateCampaigns = () => {
    return surveyCampaign.map((campaign, index) => {
      return (
        <div className="col-6" key={index} >
          <div className="campaign-box mt-3" style={{ "minHeight": "150px" }}>
            <img src={campaign.logo} className="img-fluid  " style={{ "width": "80%" }} alt="Survey Campaigns"/>
            <input type="checkbox" name="campaign" value={campaign.id} onChange={handleChange} className="float-right" />
          </div>
        </div>
      )
    })
  }

  return (
    loading ? <Loader /> :
      <div className="container" style={{ backgroundColor: "white" }}>
        <h6 className="theme-color mt-2" style={{ textAlign: "center" }}><b>SELECT BRANDS WHICH YOU LIKE</b></h6>
        <form onSubmit={handleSubmit}>
          <div className="col-md-12 pd-0">
            <div className="row">
              {populateCampaigns()}
            </div>
            <div className="row">
              <div className="col-md-12 mt-3" style={{ textAlign: "center" }} >
                <button type="submit" className="btn btn-success" ><b>Submit</b></button>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
}

export default Survey;