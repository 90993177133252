import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const CampaignContext = createContext();

const CampaignContextProvider = (props) => {
  
  const [surveyCampaign, setSurveyCampaign] = useState([]);
  const [popupCampaign, setPopupCampaign] = useState([]);
  const [thankYouCampaign, setThankYouCampaign] = useState([]);

  useEffect(() => {
    const getSurveyCampaigns = async () => {
      try {
        let response = await axios.get(`/all-campaigns`);
        setSurveyCampaign(response.data.socialBoxCampaign.filter(c => c.type === 'surveyCampaign'))
        setPopupCampaign(response.data.socialBoxCampaign.filter(c => c.type === 'popupCampaign'))
        setThankYouCampaign(response.data.socialBoxCampaign.filter(c => c.type === 'thankYouCampaign'))
      } catch (err) {
        console.log("Get campaigns API Error: ", err)
      }
    }
    getSurveyCampaigns();


  }, []);
  return (
    <CampaignContext.Provider value={{ surveyCampaign, popupCampaign, thankYouCampaign }}>
      {props.children}
    </CampaignContext.Provider>
  );
}

export default CampaignContextProvider;