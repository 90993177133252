import React, { Component, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom'
import queryString from 'query-string';
//const baseURL = 'http://localhost:1337/api/v1';
const baseURL = process.env.REACT_APP_HOME_URL;
//const baseURL = 'http://localhost:1330/api/v1';
//const baseURL = 'http://13.213.32.154:1337/api/v1';
const defaultUrl = 'https://www.brandsonroad.com/';

const Home = (props) => {
    const [qrCode, setqrCode] = useState([]);
    const [campaignFetched, setcampaignFetched] = useState(false);
    const [redirect, setRedirect] = useState(false);
    let parameters = queryString.parse(props.location.search);
    
    // if(parameters){
    //     setParams(parameters);
    // }
    
    useEffect(() => {
        //fetch the desired campaign
        fetchQrDetails(parameters, baseURL);
    },[]);

    useEffect(() => {
        if(campaignFetched){
            let promise = Promise.resolve(fetchTaxiLocation(baseURL));
            promise
              .then((data) => { console.log("data for captureImpressions(): ", data);
                                if(data){
                                  captureImpressions(baseURL, data.lat, data.lng);
                                  return data;
                                }else{
                                  captureImpressions(baseURL, 0, 0);
                                  return 1;
                                }
                                
                              })
              .then((data) => {
                                setcampaignFetched(false) 
                                return data;
                              })
              .then((data) => {
                                console.log("4. You are being redirected!: ", redirect, data)
                                setRedirect(true)
                              })
              
              .catch((err) => console.log("Error from promise: ", err)); 
        }
        
    }, [campaignFetched])


    async function fetchQrDetails(parameters, baseurl) {
        try{
            console.log("1. Fetching Qr Code Details");
            let getURL = baseurl + '/qrcode/one/';
            if(parameters){
                const data = await axios.get(getURL, {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    uniqueId: parameters.uniqueId
                },
                });
                console.log("Qr details fetched: ", data);
                if(data.data.qrcode[0].socialboxCampaign){
                  setqrCode(data.data.qrcode[0]);
                }else {
                  let modified = data.data.qrcode[0];
                  let link = {link: defaultUrl}
                  modified.socialboxCampaign = link
                  setqrCode(modified);
                }
                
                setcampaignFetched(true);
            }   
        }catch(err){
            console.log("An error occured while fetching QR Codes: ", err);
        }      
    }
    async function fetchTaxiLocation(baseurl) {
      try{
          console.log("2. Fetching taxi location");
          let getURL = baseurl + '/led/get';
          if(qrCode.taxi){
              const data = await axios.get(getURL, {
              headers: {
                  'Content-Type': 'application/json',
              },
              params: {
                  id: qrCode.taxi.ledCard
              },
              });
              console.log("data from fetch taxi location: ", data);
              
              return data.data;   
          }else {
            console.log("Qr Code not linked to any taxi");
            return null
          }   
      }catch(err){
          console.log("An error occured while fetching Taxi Location: ", err);
          return 
      }      
  }
    async function captureImpressions(baseurl, lat, lng){
      try{
        console.log("3. Fetching taxi locations: ");
        let postUrl = baseurl + '/social-box/campaign/hits';
        let values = new Object({});
        // object to send in post request
        values = {...values, 
                  qrcode: qrCode.id, 
                  socialboxcampaign: qrCode.socialboxCampaign.id,  
                  taxi: qrCode.taxi.id, 
                  lat: lat, 
                  lng: lng
                }
        const data = await axios.post(postUrl, values,{
          headers: {
              'Content-Type': 'application/json',
          }
          });
        console.log("the data from campaign post req is: ", data);
        return data;
      }catch(err){
        console.log("An error occured while posting impressions: ", err);
        return err;
      }
    }
   
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                {/* <h1 className="float-left display-3 mr-4">404</h1> */}
                <h4 className="pt-3">You will be redirected shortly ...</h4>
                {redirect ? 
                    // (<Redirect to= {{ pathname: `${qrCode.socialboxCampaign.link}`}} />) 
                    (window.location.replace(qrCode.socialboxCampaign.link)):
                    //(<h1>Redirect is true</h1>) :
                    (<h1></h1>) 
                }
              </div>
              <div className="clearfix">
                <p className="float-left">To scan the Qr-Code, please <a style={{color:"white"}} href='https://qrcodescan.in/'>click</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div>
        { campaignFetched ? 
            // (<Redirect to= {{ pathname: `${qrCode.socialboxCampaign.link}`}} />) 
            (window.location.replace(qrCode.socialboxCampaign.link)):
            (<h1></h1>) 
        }
        </div> */}
      </div>
    );
  
}

export default Home;