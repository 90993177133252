import axios from "axios";
import { toastr } from 'react-redux-toastr';
import { history } from '../App';
export const axiosMiddleware = () => {


  const toastrType = 'error';
  const toastrOptions = {
    icon: toastrType,
    status: toastrType
  }

  //axios.defaults.baseURL = 'http://localhost:1337/api/v1/social-box/mobile';
  //axios.defaults.baseURL = 'http://localhost:1330/api/v1/social-box/mobile'; //Development Backend Container
  //axios.defaults.baseURL = 'http://13.213.32.154:1337/api/v1/social-box/mobile';
  //axios.defaults.baseURL = 'https://www.admin.brandsonroad.com/api/v1/social-box/mobile';
  axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

  axios.interceptors.response.use((response) => {
    return response;
  }, err => {
    console.log('[middleware][axios]Error From API', err);
    try {
      if (err.response.status === 401) {
        console.log('UNAUTHORIZED Error', JSON.stringify(err));
        toastr.light('UNAUTHORIZED', "Session Expired", toastrOptions)
        return Promise.reject([{ error: 'UNAUTHORIZED', message: "Session Expired" }])
      } else if (err.response.status === 400) {
        console.log('Bad Request', JSON.stringify(err.response));
        err.response.data.problems.map(er => toastr.light(err.response.data.code, er, toastrOptions))
        return Promise.reject(err.response)
      }else if (err.response.status === 402) {
        console.log('Bad Request', JSON.stringify(err.response));
        toastr.light('user already done the survey', err.response.data, toastrOptions);
        history.push('/userExist')
        return Promise.reject(err.response)
      } else if (err.response.status === 403) {
        console.log('Access Denied Request', JSON.stringify(err.response));
        toastr.light('Access Denied', err.response.data, toastrOptions);
        return Promise.reject(err.response)
      } else if (err.response.status === 404) {
        console.log('Not Found Error', JSON.stringify(err.response));
        toastr.light(err.response.data.message, err.response.data.statusText, toastrOptions)
        history.push('/error-404')
        return Promise.reject(err.response)
      }else if (err.response.status === 417) {
        console.log('Not Found Error', JSON.stringify(err.response));
        toastr.light(err.response.data.message, err.response.data.statusText, toastrOptions)
        history.push('/qrInstruction')
        return Promise.reject(err.response)
      } else if (err.response.status === 500) {
        console.log('Server Error', JSON.stringify(err.response));
        history.push('/error-500')
        err.response.data.errors.forEach((e) => {
          toastr.light(e.error, e.message, toastrOptions)
        })
        return Promise.reject(err.response)
      } else {
        history.push('/error-500')
      }
    } catch (err) {
      console.log("[middleware][axios][catch-block]", JSON.stringify(err))
      history.push('/error-500')
      return;
    }
  });
}
