import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import {Link} from 'react-router-dom'

class qrInstruction extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                {/* <h1 className="float-left display-3 mr-4">404</h1> */}
                <h4 className="pt-3">Please scan qr code then proceed</h4>

              </div>
              <div className="clearfix">
                <p className="float-left">To scan the Qr-Code, please <a style={{color:"white"}} href='https://qrcodescan.in/'>click</a>
</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default qrInstruction;
